import React, { useEffect, useState } from 'react';

import ReactTable from 'react-data-table-component';
import getCertificates from '../VenafiServiceCalls/getCertificates.js';
import searchCertificates from '../VenafiServiceCalls/searchCertificates';
import AdminModal from '../VenafiTableUpdate/AdminModal.js';
import FilterTableSearch from '../FilterTableSearch/FilterTableSearch.js';
import { columns, ExpandableRow } from '../VenafiTable/TableOptions';
import { OktaConfig } from 'config/okta';
import BeatLoader from "react-spinners/BeatLoader";

//import {useRowState} from "react-table/src/plugin-hooks/useRowState";

export default function VenafiTable({ access_token, user, expiry, userId })
{
   const ROWLIMIT = 50;
   const [countOfSelectedRows,setCount]=useState(0);
   const [data, setData] = useState([]);
   const [modalShow, setModalShow] = useState(false);
   const [rowData, setRowData] = useState({});
   const [loading, setLoading] = useState(false);
   const [loaded, setLoaded] = useState(false);
   const [totalRows, setTotalRows] = useState(0);
   const [isSearch, setIsSearch] = useState(false);
   const [paginationPerPage, setPaginationPerPage] = useState(ROWLIMIT);
   const [searchValues, setSearchValues] = useState({
      entity: '',
      term: '',
   });
   const [paginationFlag, setpaginationFlag] = useState(true);

   let showsearchedRecords =() =>
   {
      return isSearch ? `Search Result Count - ${totalRows}` : `Last & Next Two Months Count - ${totalRows}`;
   }
   const tableTitle = <div><p>{showsearchedRecords()}</p><span style={{fontSize:"small"}}>Select All</span></div>;

   useEffect(() => {
      if(expiry <= Date.now()) {
         window.location.href = OktaConfig.redirectUrl.substring(
            0,
            OktaConfig.redirectUrl.length - 8
         );
      }
      loadDashboard();
      // eslint-disable-next-line
   }, []);

   let loadDashboard = () => {
      setLoaded(false);
      setLoading(true);
      getCertificates(access_token, ROWLIMIT).then((res) => {
         console.log(' <> dashboard response', res);
         setLoading(false);
         setLoaded(true);
         setData(res.body.items);
         setPaginationPerPage(ROWLIMIT);
         setTotalRows(res.body.items.length);
         setCount(0);
         setpaginationFlag(true);
      });
   };

   let searchTerm = (entity, term) => {
      setIsSearch(true);
      console.log('searching');
      setSearchValues((prevValue) => ({
         ...prevValue,
         entity,
         term,
      }));
      setLoaded(false);
      setLoading(true);
      setCount(0);
      console.log(`search values entity = ${entity} and term =${term}`);
      searchCertificates(access_token, entity, term).then((res) => {
         if (res && res.body) {
            console.log('search items', res.body);
            setData(res.body);
            setLoading(false);
            setLoaded(true);
            setPaginationPerPage(ROWLIMIT);
            setTotalRows(res.body.length);
            setpaginationFlag(false);
         } else {
            console.log('No items found', res);
         }
      });
   };

   let clearSearch = () => {
      console.log('clear');
      if (isSearch) {
         setIsSearch(false);
      }  
      loadDashboard();
   };

   let forceSyncRefresh = () => {
      if (isSearch) 
        searchTerm(searchValues.entity, searchValues.term);
      else 
        loadDashboard();
     };

   let handleSelectedRowsChange = (item) => {
      setCount(item.selectedCount);
      setRowData(item.selectedRows);
   };

   let handleAdminModel=(showFlag)=> {
      setModalShow(showFlag);
   }

   let updateHandler = () => {
      if (isSearch) {
      searchTerm(searchValues.entity, searchValues.term);
   } else {
      loadDashboard();
   }
   };

   return (<>
      <div className="ncss-row">
         <div>
            <AdminModal
               show={modalShow}
               onHide={() => setModalShow(false)}
               getRes={updateHandler}
               row={rowData}
               access_token={access_token}
               user={user}
               setLoading={setLoading}
            />
         </div>
         <div style={{padding: '10px 0', textAlign: 'center'}}>
            <FilterTableSearch
               handleSearch={searchTerm}
               clearSearch={clearSearch}
               selctedRows={countOfSelectedRows}
               rows={rowData}
               setRowData={setRowData}
               showModel={handleAdminModel}
               access_token={access_token}
               user={user}
               getRes={forceSyncRefresh}
               setLoading={setLoading}
            ></FilterTableSearch>
         </div>
         <div style={{padding: '0 5% 5% 5%'}}>
            {data.length !== 0 && loaded ?
         <ReactTable
            title={tableTitle}
            columns={columns}
            data={data}
            defaultSortField="valid_to"
            defaultSortAsc={true}
            selectableRows={true}
            selectableRowsHighlight={true}
            onSelectedRowsChange={handleSelectedRowsChange}
            clearSelectedRows={true}
            expandableRows={true}
            expandOnRowClicked
            expandableRowsComponent={<ExpandableRow />}
            striped={true}
            progressPending={loading}
            progressComponent={<BeatLoader color="#00BFFF" size={20} margin={10} />}
            pagination={paginationFlag}
            paginationTotalRows={totalRows}
            paginationPerPage={paginationPerPage}
            dense
            onRowDoubleClicked={(row) => {
               setModalShow(true);
               setRowData(row);
            }}
            highlightOnHover={true}
            fixedHeader={true}
            keyField="guid"
         />: <ReactTable title="No Certificates Found" progressPending={loading} progressComponent={<BeatLoader color="#00BFFF" size={20} margin={10} />} /> }
         </div>
      </div>
  </> );
}
