import Swal from 'sweetalert2';

  export let animatedAlertbox = (alertMessage) => {
    Swal.fire({
      icon: 'warning',
      text: alertMessage,
    })
  }

  export let animatedConfirmbox = (alertMessage,selectedItems,submitData) => {
    Swal.fire({
      text: alertMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Update'
    }).then((result) => {
      if (result.isConfirmed) {
        submitData(selectedItems);
      }
    })
}

export let animatedSuccessAlert=(title)=>
    {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: title,
        showConfirmButton: false,
        timer: 2500
      })
    }