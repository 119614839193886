import React from 'react';
import Dashboard, { AccessError } from 'components/Dashboard';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaConfig } from 'config/okta';
import { Authenticate } from 'components/Login/login';

const config = {
   issuer: OktaConfig.baseUrl,
   redirectUri: OktaConfig.redirectUrl,
   clientId: OktaConfig.clientID,
   pkce: true,
};
const App = (props) => {
   return (
      <Router>
         <Security {...config}>
            <Route exact path="/" component={Authenticate} />
            <Route exact path="/login" component={Authenticate} />
            <Route exact path="/redirect" component={Dashboard} />
            <Route exact path="/accesserror" component={AccessError} />
         </Security>
      </Router>
   );
};

export default App;
