import React  from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactTable from "react-data-table-component";


const CustomAssignmentTitle = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.assignmentGroup}
            </div>
        </div>
    </div>
);
const CustomADistributionListTitle = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.distributionList}
            </div>
        </div>
    </div>
);
const CustomSlackTitle = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.slackChannel}
            </div>
        </div>
    </div>
);
const CustomForceSyncFlag = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.force_sync}
            </div>
        </div>
    </div>
);
const CustomChangeByTitle = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.changed_by}
            </div>
        </div>
    </div>
);
const CustomChangedTimeTitle = ({ row }) => (
    <div>
        {}
        <div>{row.title}</div>
        <div>
            <div  style={{ color: 'black', overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipses' }}>
                {}
                {row.changed_time}
            </div>
        </div>
    </div>
);
const columns = [
    { name: 'Assignment-Group', selector: 'assignmentGroup', sortable: true, grow: 5 , maxWidth: '600px',cell: row => <CustomAssignmentTitle row={row} />,},
    { name: 'SlackChannel', selector: 'slackChannel', sortable: true, grow: 5 , maxWidth: '600px',cell: row => <CustomSlackTitle row={row} />,},
    { name: 'DistributionList', selector: 'distributionList', sortable: true, grow: 5 , maxWidth: '600px',cell: row => <CustomADistributionListTitle row={row} />,},
    { name: 'Force Sync', selector: 'force_sync', sortable: true, grow: 5 ,cell: row => <CustomForceSyncFlag row={row} />,},
    { name: 'Changed By', selector: 'changed_by', sortable: true, grow: 5 ,cell: row => <CustomChangeByTitle row={row} />,},
    { name: 'Changed Time', selector: 'changed_time', sortable: true, grow: 5,cell: row => <CustomChangedTimeTitle row={row} />,}
];
function HistoryModal(props) {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                centered
                dialogClassName="modal-120w"
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.cnname} - History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactTable
                            columns={columns}
                            data={props.data}
                            defaultSortField="changed_time"
                            defaultSortAsc={false}
                            striped={true}
                            dense
                            highlightOnHover={true}
                        />
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button className="ncss-btn-secondary-dark" onClick={props.onHide}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default HistoryModal;