const isProd = window.location.hostname === 'certificates.nike.com';
export default async function findCertificatesByGuid(access_token,data) 
{
   console.log("is prod", isProd)
   const env = isProd ? 'prod' : 'test';
   const response = await fetch(
      `https://sro-team-${env}.nikecloud.com/ssl-dashboard-api/cert/updatedatabyguid`,
      {
         method: 'PATCH',
         headers: {
            'Content-Type': 'application/json',
            'okta-token' : 'Bearer '+access_token
          },
         cache: 'no-cache',
         body: JSON.stringify(data),
      }
   );
   return response.json();
}


