import crypto from 'crypto';
import React from 'react';
import { OktaConfig } from 'config/okta';
import sreLogo from '../Dashboard/sreLogo.png';

export function Authenticate() {
   const base64Encode = (str) => {
      return str
         .toString('base64')
         .replace(/\+/g, '-')
         .replace(/\//g, '_')
         .replace(/=/g, '');
   };
   const sha256 = (buffer) => {
      return crypto.createHash('sha256').update(buffer).digest();
   };
   const verifier = base64Encode(crypto.randomBytes(80));
   const challenge = base64Encode(sha256(verifier));
   const params = new URLSearchParams();
   const state = base64Encode(crypto.randomBytes(1));
   params.set('client_id', OktaConfig.clientID);
   params.set('code_challenge', challenge);
   params.set('code_challenge_method', 'S256');
   params.set('redirect_uri', OktaConfig.redirectUrl);
   params.set('response_type', 'code');
   params.set('scope', 'offline_access profile openid email legacy_username');
   params.set('state', state);
   const authUrl = OktaConfig.baseUrl.concat('/authorize?', params.toString());
   // 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize?' + params.toString()
   localStorage.setItem('verifier', verifier);
   localStorage.setItem('client_id', OktaConfig.clientID);

   const savedErrorCount = Number(
      localStorage.getItem('errorCount')
   ).toString();

   localStorage.setItem(
      'errorCount',
      (Number(savedErrorCount) + 1).toString()
   );
   window.location.href = authUrl;
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
         }}
      >
         <img src={sreLogo} alt={'SRE'} />
         <h4>Authorizing...</h4>
      </div>
   );
}

// export async function Authorize() {
//    const tokenExpiry = await Authenticate();
//    if (tokenExpiry > Date.now()) {
//       return Dashboard();
//    } else {
//         return (
//             <div
//                style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   height: '90vh',
//                }}
//             >
//                <img src={sreLogo} alt={'SRE'} />
//                <h4>Authorizing...</h4>
//             </div>
//          );
//       }
// }

export const generateToken = async () => {
   const search = window.location.search.split('&');
   const code = search[0].split('=')[1];
   // const state = search[1].split('=')[1];
   const verifier = localStorage.getItem('verifier');
   const clientId = localStorage.getItem('client_id');
   const params = new URLSearchParams();
   params.set('grant_type', 'authorization_code');
   params.set('code', code);
   params.set('client_id', clientId);
   params.set('code_verifier', verifier);
   params.set('redirect_uri', OktaConfig.redirectUrl);
   const tokenUrl = OktaConfig.baseUrl.concat('/token');
   // 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token'
   // const body = `grant_type=authorization_code&code=${code}&client_id=nike.sre.ssl-certificate-dashboard&code_verifier=${verifier}&redirect_uri=http://localhost:3000/redirect`
   const body = params.toString();
   const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
   });
   let res = await response.json();
   // const { expires_in, access_token, id_token } = res;
   res.expiry = new Date().setSeconds(res.expires_in);
   window.localStorage.setItem(
      'expires_at',
      new Date().setSeconds(res.expires_in)
   );
   window.localStorage.setItem('access_token', res.access_token);
   window.localStorage.setItem('refresh_token', res.refresh_token);
   // window.localStorage.setItem("id_token", id_token);
   if (res.access_token) {
      const userUrl = OktaConfig.baseUrl.concat('/userinfo');
      const uresponse = await fetch(userUrl, {
         method: 'POST',
         headers: { Authorization: 'Bearer ' + res.access_token },
         body,
      });
      let ures = await uresponse.json();
      console.log('ures ', ures);
      res.user = ures.email;
      res.userId = ures.legacy_username;
   }
   console.log('login creds ', res);
   return res;
};

export const refreshToken = async () => {
   const search = window.location.search.split('&');
   const code = search[0].split('=')[1];
   const verifier = localStorage.getItem('verifier');
   const params = new URLSearchParams();
   params.set('grant_type', 'refresh_token');
   params.set('code', code);
   params.set('client_id', OktaConfig.clientID);
   params.set('code_verifier', verifier);
   params.set('redirect_uri', OktaConfig.redirectUrl);
   params.set('scope', 'offline_access');
   const tokenUrl = 'https://nike-qa.oktapreview.com/oauth2/default/v1/token';
   const body = params.toString();
   const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
   });
   let res = await response.json();
   console.log('refresh res', res);
   // const { expires_in, access_token, id_token } = res;
   res.expiry = new Date().setSeconds(res.expires_in);
   window.localStorage.setItem(
      'expires_at',
      new Date().setSeconds(res.expires_in)
   );
   window.localStorage.setItem('access_token', res.access_token);
   // window.localStorage.setItem("id_token", id_token);
   if (res.access_token) {
      const userUrl = OktaConfig.baseUrl.concat('/userinfo');
      const uresponse = await fetch(userUrl, {
         method: 'POST',
         headers: { Authorization: 'Bearer ' + res.access_token },
         body,
      });
      let ures = await uresponse.json();
      console.log('refresh res ', ures);
      res.user = ures.email;
      res.userId = ures.legacy_username;
   }
   console.log('refresh res ', res);
   return res;
};