const testApi = 'nike-qa.oktapreview.com';
const prodApi = 'nike.okta.com';
const testAuthServer = 'ausa0mcornpZLi0C40h7';
const prodAuthServer = 'aus27z7p76as9Dz0H1t7';
const isProd =
   window.location.hostname === 'certificates.nike.com';

const baseUrl = () => {
   if (isProd) {
      return `https://${prodApi}/oauth2/${prodAuthServer}/v1`;
   }
   return `https://${testApi}/oauth2/${testAuthServer}/v1`;
};
const redirectUrl = () => {
   if (isProd) {
      return `https://certificates.nike.com/redirect`;
   }
   if (window.location.hostname === 'localhost') {
      return 'http://localhost:3000/redirect';
   }
   return `https://certificates-qa.nike.com/redirect`;
};

export const OktaConfig = {
   baseUrl: baseUrl(),
   clientID: 'nike.sre.ssl-certificate-dashboard',
   redirectUrl: redirectUrl(),
};
