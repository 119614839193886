import React from 'react';
import { Navbar } from 'react-bootstrap';
// import styles from './Header.module.scss';
import './nike-glyphs.css';

export default function Header(props) {
   return (  <Navbar style={{backgroundColor: 'black', width: '100%'}}>
   <Navbar.Brand href="#home" style={{color: 'white'}}>
     <img
       alt=""
       src="/sreLogo.png"
       width="30"
       height="30"
       className="d-inline-block align-top"
     />{' '}
     Certificate Dashboard  
   </Navbar.Brand>
   <span style={{textAlign: 'right', flex: 1, color: 'white'}}>{props.user}</span>
 </Navbar>
   );
}
