const isProd = window.location.hostname === 'certificates.nike.com';

export default async function getHistoryDetails(access_token, entity,validto) {
    const env = isProd ? 'prod' : 'test';
    const requestBody = {cn: entity.toLowerCase (),valid_to:validto};

    console.log ( " get the value" + JSON.stringify ( requestBody ) );
    const response  = await fetch (
        `https://sro-team-${env}.nikecloud.com/ssl-dashboard-api/cert/history` ,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'okta-token' : 'Bearer '+access_token
          },
          cache: 'no-cache',
          body: JSON.stringify(requestBody),
        }
    );
    return response.json();
}


